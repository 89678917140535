import urls from "utils/urls";

export const addCsrf = [
  function fullfilled(config) {
    if (config.url.startsWith("/api")) {
      const csrfToken = window.localStorage.getItem("csrfToken");
      config.headers.common["X-CSRF-Token"] = csrfToken;
    }

    return config;
  },
  function rejected(error) {
    return error;
  }
];

export const handleUnauthorized = [
  function fullfilled(config) {
    return config;
  },
  function rejected(error) {
    if (
      error.response.status === 401 &&
      error.config.url.startsWith("/api") &&
      !window.location.pathname.startsWith(urls.login)
    )
      window.location.replace(urls.login);
    return Promise.reject(error);
  }
];
