import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import withSession from "utils/withSession";
import urls from "utils/urls";

const Login = lazy(() => import("components/Login"));
const Start = lazy(() => import("components/Start"));
const Users = lazy(() => import("components/Users"));
const Details = lazy(() => import("components/Details"));
const Events = lazy(() => import("components/Details/Events"));
const Transmitters = lazy(() => import("components/Details/Transmitters"));
const TusNet = lazy(() => import("components/Details/TusNet"));
const Surveillance = lazy(() => import("components/Details/Surveillance"));
const Settings = lazy(() => import("components/Details/Settings"));

export default function RoutesSwitch() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path={urls.start} component={withSession(Start)} />
        <Route path={urls.users} component={withSession(Users)} />
        <Route exact path={urls.details} component={withSession(Details)} />
        <Route exact path={urls.events} component={withSession(Events)} />
        <Route
          exact
          path={urls.transmitters}
          component={withSession(Transmitters)}
        />
        <Route exact path={urls.tusNet} component={withSession(TusNet)} />
        <Route
          exact
          path={urls.surveillance}
          component={withSession(Surveillance)}
        />
        <Route path={urls.settings} component={withSession(Settings)} />
        <Route path={urls.login} component={Login} />
        <Redirect path="*" to={urls.login} />
      </Switch>
    </Suspense>
  );
}
