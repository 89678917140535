import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: 80px;
  position: relative;
  z-index: 2;
  box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.1);
`;

export const LinkWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid transparent;
  padding: 0 20px;
  position: relative;
  ${props =>
    !props.selected
      ? ""
      : `background-color: #f5f5f5;
  &:after {
    height: 5px;
    transition: background-color 0.2s ease-in-out;
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #00b7c5;
  }
  `}
`;
