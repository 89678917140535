import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";
import NavigationBar from "components/global/NavigationBar";
import ErrorBoundary from "components/global/ErrorBoundary";
import { addCsrf, handleUnauthorized } from "utils/interceptors";
import { SessionProvider } from "./SessionContext";
import { DetailsProvider } from "./DetailsContext";
import RoutesSwitch from "./RoutesSwitch";
import "./index.css";

axios.interceptors.request.use(...addCsrf);
axios.interceptors.response.use(...handleUnauthorized);

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary>
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <SessionProvider>
          <NavigationBar />
          <DetailsProvider>
            <RoutesSwitch />
          </DetailsProvider>
        </SessionProvider>
      </Router>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById("root")
);
