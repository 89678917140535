import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import SessionContext from "SessionContext";
import urls from "./urls";

export default function withSession(WrappedComponent) {
  return function ComponentWithSession(props) {
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const { setSession, clearSession } = useContext(SessionContext);
    useEffect(() => {
      axios
        .post("/api/sessions/check")
        .then(response => {
          if (response.error) throw new Error(response.error);
          const { csrfToken, ...session } = response.data;
          window.localStorage.setItem("csrfToken", csrfToken);
          setSession(session);
          setLoading(false);
        })
        .catch(error => {
          console.error(error);
          clearSession();
          setLoading(false);
          setRedirect(true);
        });
    }, [setSession, clearSession]);

    return loading ? null : redirect ? (
      <Redirect to={urls.login} />
    ) : (
      <WrappedComponent {...props} />
    );
  };
}
