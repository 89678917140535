import React, { useRef, useState } from "react";
import axios from "axios";
const Context = React.createContext();
export default Context;

export function SessionProvider({ children }) {
  const [session, setSession] = useState(null);
  const clearSession = useRef(() =>
    axios
      .post("/api/sessions/logout")
      .then(() => {
        window.localStorage.removeItem("csrfToken");
        setSession(null);
      })
      .catch(console.error)
  );

  return (
    <Context.Provider
      value={{
        session,
        setSession,
        clearSession: clearSession.current
      }}
    >
      {children}
    </Context.Provider>
  );
}
