import React, { useContext } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import urls from "utils/urls";
import { capitalize } from "utils";
import SessionContext from "SessionContext";
import { Wrapper, LinkWrapper } from "./styled";

const topUrls = ["login", "start", "users"];

export default function NavigationBar() {
  return (
    <Wrapper>
      {topUrls.map(urlKey => (
        <NavigationLink key={urlKey} urlKey={urlKey} />
      ))}
    </Wrapper>
  );
}

function NavigationLink({ urlKey }) {
  const match = useRouteMatch({ path: urls[urlKey] });
  const { session, clearSession } = useContext(SessionContext);
  return (
    <LinkWrapper selected={match}>
      {urlKey === "login" && session ? (
        <Link to={urls[urlKey]} onClick={clearSession}>
          Logout
        </Link>
      ) : (
        <Link to={urls[urlKey]}>{capitalize(urlKey)}</Link>
      )}
    </LinkWrapper>
  );
}
