import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    if (!this.state.error) return this.props.children;

    return (
      <div>
        <h1>An unexpected error occured</h1>
        <p>Server is currently down. Please try again later</p>
        {process.env.NODE_ENV !== "production" && (
          <div>{this.state.error.toString()}</div>
        )}
      </div>
    );
  }
}

export default ErrorBoundary;
