import React, { useRef, useState } from "react";
import axios from "axios";
const Context = React.createContext();
export default Context;

export function DetailsProvider({ children }) {
  const [name, setName] = useState(new Map());
  const loadName = useRef(id =>
    axios
      .get(`/api/servers/${id}/name`)
      .then(response => setName({ ...name, [id]: response.data }))
      .catch(console.error)
  );

  return (
    <Context.Provider
      value={{
        name,
        loadName
      }}
    >
      {children}
    </Context.Provider>
  );
}
